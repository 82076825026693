import { BootstrapData } from '../../types'
import { BOOTSTRAP_DATA, SERVICES_LOADER } from './moduleNames'
import { servicesConfigs, servicesFactories } from '../../services'
import { createServicesManager, createServicesMap, ServicesManager } from '@wix/services-manager'

export interface IServices {
	init: () => Promise<void>
	getService: ServicesManager['getService']
}

const ServicesLoader = (bootstrapData: BootstrapData): IServices => {
	const { serviceDefinitionToConfig } = bootstrapData
	let serviceManager: ServicesManager

	const serviceDefinitionToFactoryPromise = Promise.all(
		Object.keys(serviceDefinitionToConfig!).map((serviceDefinition: string) => {
			const factoryGetter = servicesFactories[serviceDefinition]
			if (!factoryGetter) {
				console.error(`Service ${serviceDefinition} is not supported`)
				return Promise.resolve([serviceDefinition, () => {}])
			}
			return factoryGetter().then((implementation) => [serviceDefinition, implementation])
		})
	).then((servicesArray) => Object.fromEntries(servicesArray))

	return {
		init: async () => {
			const serviceDefinitionToFactory = await serviceDefinitionToFactoryPromise
			serviceManager = createServicesManager(
				createServicesMap(
					Object.entries(serviceDefinitionToConfig!).map(([definition, config]) => ({
						definition,
						config: config || servicesConfigs[definition],
						impl: serviceDefinitionToFactory[definition],
					}))
				)
			)
		},
		getService: (serviceDefinition) => {
			if (!serviceManager) {
				throw new Error('Service manager is not initialized')
			}
			return serviceManager.getService(serviceDefinition)
		},
	}
}

export default {
	factory: ServicesLoader,
	deps: [BOOTSTRAP_DATA],
	name: SERVICES_LOADER,
}
