import { Experiments, ViewMode } from '@wix/thunderbolt-symbols'
import { defineService } from '@wix/services-manager/helpers'
import { ServiceDefinition } from '@wix/services-manager/types'
import { Signal } from '@wix/services-manager/core-services/signals'

export interface ITestServiceConfig {
	experiments: Experiments
	initialViewMode?: ViewMode
}

export interface ITestService {
	isOpen: (experimentName: string) => boolean
	viewMode: Signal<ViewMode>
	setViewMode: (viewMode: ViewMode) => void
}

export type ITestServiceDefinition = ServiceDefinition<ITestService, ITestServiceConfig>

export const TestServiceDefinition = defineService<ITestService, ITestServiceConfig>('experiment-service')
